import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormArray, FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";
import { NoteCategoryService } from "@root/data/market/noteCategories/services/note-category.service";
import { INote } from "@root/data/market/notes/models/note.model";
import { NotesService } from "@root/data/market/notes/services/notes.service";
import { mapNoteCategoryToDropdownOption } from "@root/data/market/notes/utilities/notes.utilities";
import { BasePaginatedScrollWithSearchAbstractComponent } from "@root/shared/abstracts/base-paginated-scroll-with-search/base-paginated-scroll-with-search.abstract";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DatepickerRangeInputComponent } from "@root/shared/datepicker-range-input/datepicker-range-input.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { NoteComponent } from "@root/shared/note-view/components/note/note.component";
import { NoteFormControls } from "@root/shared/note-view/components/note/note.form-controls";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import { generateDropdownOptionsFromLookUps } from "@root/shared/utilities/dropdown-options.utilities";
import { isElementScrolledToTheBottom } from "@root/shared/utilities/element-poistion.utilities";
import {
  castControlFromAbstractToFormControl,
  markAllControlsAsTouchedAndDirty,
} from "@root/shared/utilities/form.utilities";
import { SelectSearchAvailableResponsibleAgentsComponent } from "@root/views/main/property/property-events/customer-follow-up/select-search-available-responsible-agents/select-search-available-responsible-agents.component";
import { Observable } from "rxjs/internal/Observable";
import { tap } from "rxjs/internal/operators/tap";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";

@Component({
  selector: "est-note-view",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    SelectFieldComponent,
    DatepickerRangeInputComponent,
    SearchInputComponent,
    NoteComponent,
    InputComponent,
    TextAreaComponent,
    ButtonComponent,
    LoadingOverlayComponent,
    SelectSearchFieldComponent,
    SelectSearchAvailableResponsibleAgentsComponent,
  ],
  templateUrl: "./note-view.component.html",
  styleUrl: "./note-view.component.scss",
})
export class NoteViewComponent extends BasePaginatedScrollWithSearchAbstractComponent implements OnInit {
  readonly #fb = inject(NonNullableFormBuilder);
  journeyId = input<number | null>(null);
  noteFormArray = input<FormArray<FormGroup>>(this.#fb.array<FormGroup>([]));
  readonly #formControls = inject(NoteFormControls);
  readonly #noteService = inject(NotesService);
  readonly #noteCategoryService = inject(NoteCategoryService);
  userIdsSignal = signal<number[] | null>(null);
  noteCategoryIdsSignal = signal<number[] | null>(null);
  startDateSignal = signal<Date | null>(null);
  endDateSignal = signal<Date | null>(null);
  noteCategoryOptions: IDropdownOption[] = [];
  noteCategories: INoteCategory[] = [];
  createdByUsers: IDropdownOption[] = [];
  newNoteFormGroup = this.#formControls.constructNoteControl();
  protected isLoading = false;
  protected initialLoading = true;
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected readonly isElementScrolledToTheBottom = isElementScrolledToTheBottom;

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse> | void {
    if (!this.journeyId()) {
      this.isScrollLoading = false;
      return;
    }

    return this.#noteService
      .getPaginatedJourneyNotes(
        {
          pageSize: this.paginatedData.pageSize(),
          pageNumber: pageNumber,
          search: this.searchSignal(),
          ...(this.noteCategoryIdsSignal() && this.noteCategoryIdsSignal()![0] !== null
            ? { noteCategoryIds: this.noteCategoryIdsSignal()! }
            : {}),
          ...(this.userIdsSignal() && this.userIdsSignal()![0] !== null ? { userIds: this.userIdsSignal()! } : {}),
          ...(this.startDateSignal() ? { startDate: this.#transferDateWithFormat(this.startDateSignal()!) } : {}),
          ...(this.endDateSignal() ? { endDate: this.#transferDateWithFormat(this.endDateSignal()!) } : {}),
        },
        this.journeyId()!,
      )
      .pipe(
        tap((signalResponse) => {
          this.noteFormArray().clear();
          this.#formControls
            .constructNoteControls(this.paginatedData.results().concat(signalResponse.results()))
            .forEach((formGroup) => this.noteFormArray()?.push(formGroup));
          this.initialLoading = false;
          this.isLoading = false;
        }),
      );
  }

  ngOnInit() {
    this.#noteCategoryService.lookUpNoteCategories().subscribe((data) => {
      this.noteCategoryOptions = mapNoteCategoryToDropdownOption(data);
      this.noteCategories = data;
    });

    this.paginatedData.pageSize.set(5);

    if (!this.journeyId()) return;

    this.#noteService
      .lookUpCreatedByUsers(this.journeyId()!)
      .subscribe((data) => (this.createdByUsers = generateDropdownOptionsFromLookUps(data)));
  }

  #loadData(): void {
    this.initialLoading = true;
    this.noteFormArray().clear();
    this.resetPagination();
    this.loadOptions(1);
  }

  protected updateNote(note: INote): void {
    if (!this.journeyId()) return;

    this.isLoading = true;
    this.#noteService
      .updateNote(this.journeyId()!, note.id, note.subject, note.message, note.noteCategory.id)
      .subscribe(() => this.#loadData());
  }

  protected deleteNote(note: INote) {
    if (!this.journeyId()) {
      const index = this.noteFormArray().value.findIndex((x: { id: number }) => x.id === note.id);
      if (index === -1) return;
      this.noteFormArray().removeAt(index);
      return;
    }
    this.#noteService.deleteNote(this.journeyId()!, note.id).subscribe(() => this.#loadData());
  }

  protected createNewNote() {
    if (this.newNoteFormGroup.invalid) {
      markAllControlsAsTouchedAndDirty(this.newNoteFormGroup);
      return;
    }

    if (!this.journeyId()) {
      const noteCategory = this.noteCategories.find(
        (x) => x.id === this.newNoteFormGroup.controls.noteCategoryId.value!,
      );
      this.noteFormArray()!.push(
        this.#formControls.constructNoteControl(
          this.newNoteFormGroup.controls.subject.value,
          this.newNoteFormGroup.controls.message.value,
          noteCategory,
        ),
      );
      this.newNoteFormGroup = this.#formControls.constructNoteControl();
      return;
    }

    this.#noteService
      .createNote(
        this.journeyId()!,
        this.newNoteFormGroup.controls.subject.value,
        this.newNoteFormGroup.controls.message.value,
        this.newNoteFormGroup.controls.noteCategoryId.value!,
      )
      .subscribe(() => {
        this.#loadData();
        this.newNoteFormGroup = this.#formControls.constructNoteControl();
      });
  }

  #transferDateWithFormat(date: any) {
    return `${date.c.year}-${date.c.month}-${date.c.day}`;
  }
}
