import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { ICustomerOverview } from "@root/data/market/legalEntities/models/customer-overview.model";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";
import { BaseCustomerCardTabComponentComponent } from "@root/shared/abstracts/base-customer-card-tab-component/base-customer-card-tab-component.component";
import { CustomerOverviewComponent } from "@root/shared/customer-card/components/customer-card-overview/components/customer-overview/customer-overview.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";

@Component({
  selector: "est-customer-card-overview",
  standalone: true,
  imports: [CustomerOverviewComponent, LoadingOverlayComponent],
  templateUrl: "./customer-card-overview.component.html",
  styleUrl: "./customer-card-overview.component.scss",
})
export class CustomerCardOverviewComponent extends BaseCustomerCardTabComponentComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #legalEntityService = inject(LegalEntityService);
  favouriteControl = new FormControl<boolean>(false, { nonNullable: true, updateOn: "change" });
  protected customerOverview!: ICustomerOverview;

  ngOnInit() {
    this.loadData();
    this.favouriteControl.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.toggleFavourite());
  }

  override loadData(): void {
    this.isLoading = true;
    this.#legalEntityService.getCustomerOverview(this.legalEntityId()).subscribe((overview) => {
      this.customerOverview = overview;
      this.favouriteControl.patchValue(overview.isFavourite, { emitEvent: false });
      this.isLoading = false;
    });
  }

  toggleFavourite() {
    this.#legalEntityService.favouriteCustomer(this.legalEntityId()).subscribe();
  }
}
