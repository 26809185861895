import { Component, DestroyRef, inject, input, OnInit, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { INoteComment } from "@root/data/market/notes/models/note-comment.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { NoteBinder } from "@root/shared/note-view/components/note/note.binder";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";
import { TextComponent } from "@root/views/main/organization/organization-system-modules/customer-page/text/text.component";

@Component({
  selector: "est-note-comment",
  standalone: true,
  imports: [TextComponent, ButtonComponent, ReactiveFormsModule, TranslateModule, CustomDatePipe, TextAreaComponent],
  templateUrl: "./note-comment.component.html",
  styleUrl: "./note-comment.component.scss",
})
export class NoteCommentComponent implements OnInit {
  formGroup = input.required<FormGroup<any>>();
  deleteNoteComment = output<INoteComment>();
  readonly #noteBinder = inject(NoteBinder);
  readonly #destroyRef = inject(DestroyRef);
  protected isEditing = false;
  protected isConfirmingDeletion = false;
  protected initialCommentText = "";
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;

  ngOnInit() {
    this.#noteBinder.onEditing.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((ids) => {
      this.isEditing = ids.commentId === this.formGroup().controls["id"].value;
    });

    this.#noteBinder.onDeleting.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((ids) => {
      this.isConfirmingDeletion = ids.commentId === this.formGroup().controls["id"].value;
    });
  }

  protected delete(): void {
    this.deleteNoteComment.emit(this.formGroup().value);
  }

  protected toggleEditing() {
    if (this.isEditing) {
      this.formGroup().controls["comment"].setValue(this.initialCommentText);
      this.isEditing = !this.isEditing;
    } else {
      this.initialCommentText = this.formGroup().controls["comment"].value;
      this.#noteBinder.emitEditing({ commentId: this.formGroup().controls["id"].value });
    }
  }

  protected toggleDeleting() {
    if (this.isConfirmingDeletion) {
      this.isConfirmingDeletion = !this.isConfirmingDeletion;
    } else {
      this.#noteBinder.emitDeleting({ commentId: this.formGroup().controls["id"].value });
    }
  }
}
