import { Component, effect, inject, input, output, signal, WritableSignal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TeamsService } from "@root/data/market/teams/services/teams.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { listSelectTeamsHeaders } from "@root/shared/list-select-teams/list-select-teams.headers";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  selector: "est-list-select-teams",
  standalone: true,
  imports: [SearchInputComponent, SmallListTableComponent, PaginationComponent],
  templateUrl: "./list-select-teams.component.html",
  styleUrl: "./list-select-teams.component.scss",
})
export class ListSelectTeamsComponent extends BasePaginatedTableWithSearchComponent {
  readonly #teamsService = inject(TeamsService);
  readonly headers = listSelectTeamsHeaders;
  control = input<FormControl>();
  divisionIds = input.required<number[]>();
  userId = input<number>();
  controlMapper = input<Map<string, FormControl<any[] | null>>>(new Map([["", new FormControl<any[] | null>([])]]));
  stepIndex = input<number>();
  checkItem = output<ICheckedItem>();
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });

  loadDataEffect$ = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      divisionIds: this.divisionIds(),
      includeInactive: false,
    });
  });

  override loadData(
    params: IPaginationSortPayload & {
      divisionIds: number[];
      includeInactive: boolean;
    },
  ): void {
    if (this.divisionIds().length === 0) return;
    if (this.divisionIds() && this.stepIndex() === 1) {
      this.isTableLoading = true;
      this.#teamsService.getPaginatedSmallListTableInputTeams(params).subscribe((paginatedTeams) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedTeams);
        this.isTableLoading = false;
      });
    }
  }

  checkTeam(event: ICheckedItem) {
    this.checkItem.emit(event);
  }
}
