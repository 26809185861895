import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const CUSTOMER_OVERVIEW_HEADERS: ITableHeader[] = [
  {
    label: "CUSTOMERS.CUSTOMER_STAGE",
    key: "stage",
    isSortable: true,
    type: TableHeaderType.Text,
    needsTranslation: true,
  },
  {
    label: "STARTED",
    key: "createdAt",
    isSortable: true,
    type: TableHeaderType.Date,
    includeTimeInDate: true,
  },
  {
    label: "CUSTOMERS.PROPERTY_RELATION",
    key: "propertyRelation",
    isSortable: true,
    type: TableHeaderType.Text,
    placeholder: "CUSTOMERS.NO_PROPERTY_RELATION",
  },
  {
    label: "CUSTOMERS.UNIT_RELATION",
    key: "unitRelation",
    isSortable: true,
    type: TableHeaderType.Text,
    placeholder: "CUSTOMERS.NO_UNIT_RELATION",
  },
  {
    label: "EVENTS.CONTRACT_STATUS",
    key: "contractStatus",
    isSortable: true,
    type: TableHeaderType.Text,
    placeholder: "CUSTOMERS.NO_CONTRACT",
  },
  {
    label: "CUSTOMERS.RESPONSIBLE_AGENT",
    key: "responsibleAgent",
    isSortable: true,
    type: TableHeaderType.Text,
    placeholder: "CUSTOMERS.UNASSIGNED",
  },
];
