<div class="flex relative flex-row justify-between items-start">
  @if (isConfirmingDeletion) {
    <div class="absolute z-10 bg-gray-50 rounded-lg opacity-90 top-0 left-0 w-full h-full"></div>
    <div class="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-6">
      <est-button
        label="COLD_FEET"
        buttonType="button"
        buttonStyleType="button--secondary"
        (click)="toggleDeleting()"
      ></est-button>
      <est-button
        label="CUSTOMER_CARD.NOTES.DELETE_NOTE_COMMENT"
        buttonType="button"
        buttonStyleType="button--primary"
        (click)="delete()"
      ></est-button>
    </div>
  }
  <div class="flex flex-col justify-between gap-2 items-start max-w-[56.625rem]">
    <div class="flex flex-row justify-items-start gap-4 font-light text-[0.75rem]">
      <p>{{ "CREATED" | translate }}: {{ formGroup().controls["createdAt"].value | customDate: true }}</p>
      <p>{{ "CREATED_BY" | translate }}: {{ formGroup().controls["createdBy"].value }}</p>
    </div>
    @if (isEditing) {
      <div class="flex flex-wrap gap-6 items-end">
        <est-text-area
          class="w-[56.625rem] h-[4.5rem]"
          appliedClass="w-[56.625rem] h-[4.5rem]"
          [control]="castControlFromAbstractToFormControl(formGroup().controls['comment'])"
          label="CUSTOMER_CARD.NOTES.EDIT_NOTE_COMMENT"
          placeholder="CUSTOMER_CARD.NOTES.WRITE_NOTE_COMMENT"
        ></est-text-area>
        <est-button
          [disabled]="formGroup().invalid"
          buttonType="button"
          buttonStyleType="button--primary"
          label="SAVE"
          (click)="isEditing = false"
        ></est-button>
      </div>
    } @else {
      <p class="text-sm font-light">{{ formGroup().controls["comment"].value }}</p>
    }
  </div>
  @if (formGroup().controls["isOwner"].value) {
    <div class="flex flex-row justify-end gap-2">
      <est-button
        imageSrc="assets/images/icons/edit-icon.svg"
        appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 !rounded"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label=""
        imagePosition="center"
        appliedSpinnerClasses="!fill-primary"
        (click)="toggleEditing()"
      ></est-button>
      <est-button
        appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 !rounded"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label=""
        imagePosition="center"
        imageSrc="assets/images/icons/trash-icon.svg"
        appliedSpinnerClasses="!fill-primary"
        (click)="toggleDeleting()"
      ></est-button>
    </div>
  }
</div>
