import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { inject, Injectable } from "@angular/core";
import { INote } from "@root/data/market/notes/models/note.model";
import { INoteComment } from "@root/data/market/notes/models/note-comment.model";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";
import { AuthService } from "@root/data/market/auth/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class NoteFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #authService = inject(AuthService);
  #fullName = this.#authService.getFullName();
  dummyId: number = -1;

  constructNoteControl(subject?: string, message?: string, noteCategory?: INoteCategory) {
    return this.#fb.group({
      id: this.#fb.control(this.dummyId--),
      subject: this.#fb.control(subject ?? "", Validators.required),
      noteCategoryId: this.#fb.control<number | undefined>(noteCategory?.id ?? undefined, Validators.required),
      noteCategory: this.#fb.control(noteCategory),
      message: this.#fb.control(message ?? "", Validators.required),
      isOwner: this.#fb.control(true),
      canView: this.#fb.control(true),
      createdAt: this.#fb.control(new Date()),
      createdBy: this.#fb.control(this.#fullName),
      comments: this.#fb.array<FormGroup>([]),
    });
  }

  constructNoteControls(notes: INote[]): FormGroup[] {
    const array: FormGroup[] = [];
    notes.forEach((note) =>
      array.push(
        this.#fb.group({
          id: this.#fb.control(note.id),
          isOwner: this.#fb.control(note.isOwner),
          canView: this.#fb.control(note.canView),
          subject: this.#fb.control(note.subject, Validators.required),
          message: this.#fb.control(note.message, Validators.required),
          noteCategoryId: this.#fb.control(note.noteCategory.id, Validators.required),
          noteCategory: this.#fb.control(note.noteCategory),
          comments: this.#fb.array<FormGroup>(this.constructCommentControls(note.comments)),
          createdAt: this.#fb.control(note.createdAt),
          createdBy: this.#fb.control(note.createdBy),
        }),
      ),
    );
    return array;
  }

  protected constructCommentControls(comments: INoteComment[]): FormGroup[] {
    const array: FormGroup[] = [];
    comments.forEach((comment) =>
      array.push(
        this.#fb.group({
          id: this.#fb.control(comment.id, Validators.required),
          comment: this.#fb.control(comment.comment, Validators.required),
          isOwner: this.#fb.control(comment.isOwner, Validators.required),
          createdAt: this.#fb.control(comment.createdAt, Validators.required),
          createdBy: this.#fb.control(comment.createdBy, Validators.required),
        }),
      ),
    );
    return array;
  }

  constructCommentControl(): FormGroup {
    return this.#fb.group({
      id: this.#fb.control(this.dummyId--, Validators.required),
      comment: this.#fb.control("", Validators.required),
      isOwner: this.#fb.control(true, Validators.required),
      createdAt: this.#fb.control(new Date(), Validators.required),
      createdBy: this.#fb.control(this.#fullName, Validators.required),
    });
  }
}
