import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITeamLookup } from "@root/data/market/teams/models/team-lookup.model";
import { APIS } from "@root/core/api/market.apis";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ITeamDetails } from "@root/data/market/teams/models/team-details.model";

@Injectable({
  providedIn: "root",
})
export class TeamsRepository {
  readonly #http = inject(HttpClient);

  getPaginatedTeams(
    params?: IPaginationSortPayload & {
      divisionIds?: number[];
      includeInactive?: boolean;
    },
  ): Observable<IPaginatedResponse<ITeamLookup>> {
    return this.#http.get<IPaginatedResponse<ITeamLookup>>(APIS.teams.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  createTeam(teamInformation: FormData): Observable<void> {
    return this.#http.post<void>(APIS.teams.createTeam, teamInformation);
  }

  getTeamById(teamId: number): Observable<ITeamDetails> {
    return this.#http.get<ITeamDetails>(APIS.teams.getTeamById + `/${teamId}`);
  }

  updateTeamById(teamId: number, body: FormData): Observable<void> {
    return this.#http.put<void>(APIS.teams.updateTeam(teamId), body);
  }

  reactivateTeam(id: number): Observable<void> {
    return this.#http.post<void>(APIS.teams.reactivate(id), {});
  }

  deactivateTeam(id: number): Observable<void> {
    return this.#http.post<void>(APIS.teams.deactivate(id), {});
  }

  assignProperties(teamId: number, properties: number[]): Observable<void> {
    return this.#http.post<void>(APIS.teams.assignProperties(teamId), { properties });
  }

  replaceTeamManger(userId: number, teamIds: number[]): Observable<void> {
    return this.#http.put<void>(APIS.teams.replaceTeamManger, { userId: userId, teamIds: teamIds });
  }
}
