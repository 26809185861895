import { Component, input, OnInit } from "@angular/core";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";
import { NgClass, NgOptimizedImage, NgStyle, UpperCasePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { getTextColourFromHex } from "@root/data/market/notes/utilities/notes.utilities";

@Component({
  selector: "est-note-category-label",
  standalone: true,
  imports: [NgStyle, NgClass, UpperCasePipe, TranslateModule, NgOptimizedImage],
  templateUrl: "./note-category-label.component.html",
  styleUrl: "./note-category-label.component.scss",
})
export class NoteCategoryLabelComponent implements OnInit {
  noteCategory = input.required<INoteCategory>();
  canView = input(false);
  textColourClass: string = "font-white";
  protected label = "";

  ngOnInit() {
    this.textColourClass = getTextColourFromHex(this.noteCategory().colour);
    const systemLanguageId = localStorage.getItem("systemLanguage")!.slice(0, 2).toLowerCase();
    this.label =
      this.noteCategory().noteCategoryNames.find((noteCategoryName) => noteCategoryName.languageId === systemLanguageId)
        ?.name ?? this.noteCategory().noteCategoryNames[0].name;
  }
}
