import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { INote } from "@root/data/market/notes/models/note.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

export const mapNotesToSignalPaginatedResponse = (): OperatorFunction<
  IPaginatedResponse<INote>,
  ISignalPaginatedResponse<INote>
> => {
  return map((paginatedNotes) => ({
    ...mapToSignalPaginatedResponse(paginatedNotes),
  }));
};

export const mapNoteCategoryToDropdownOption = (noteCategories: INoteCategory[]): IDropdownOption[] => {
  const systemLanguageId = localStorage.getItem("systemLanguage")!.slice(0, 2).toLowerCase();
  return noteCategories.map((noteCategory) => {
    return {
      label:
        noteCategory.noteCategoryNames.find((noteCategoryName) => noteCategoryName.languageId === systemLanguageId)
          ?.name ?? noteCategory.noteCategoryNames[0].name,
      value: noteCategory.id,
    };
  });
};

export const hexToRgb = (hexColour: string) => {
  const cleanHex = hexColour.replace("#", "");

  const fullHex =
    cleanHex.length === 3
      ? cleanHex
          .split("")
          .map((char) => char + char)
          .join("")
      : cleanHex;

  const r = parseInt(fullHex.substring(0, 2), 16);
  const g = parseInt(fullHex.substring(2, 4), 16);
  const b = parseInt(fullHex.substring(4, 6), 16);

  return { r, g, b };
};

export const getTextColourFromHex = (hex: string): string => {
  const rgb = hexToRgb(hex);

  const { r, g, b } = rgb;

  const rScaled = r / 255;
  const gScaled = g / 255;
  const bScaled = b / 255;

  const toLinear = (value: number): number => {
    return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
  };

  const rLinear = toLinear(rScaled);
  const gLinear = toLinear(gScaled);
  const bLinear = toLinear(bScaled);

  const luminance = 0.2126 * rLinear + 0.7152 * gLinear + 0.0722 * bLinear;

  return luminance > 0.5 ? "text-black" : "text-white";
};
