import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressBar } from "@angular/material/progress-bar";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { ListSelectPropertiesComponent } from "@root/shared/list-select-properties/list-select-properties.component";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { UploadFileDropZoneComponent } from "@root/shared/upload-file-drop-zone/upload-file-drop-zone.component";
import { getFileSize } from "@root/shared/utilities/files.utilities";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { DocumentService } from "@root/data/market/documents/services/document.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { FilesUploadCounterComponent } from "@root/shared/Files-upload-counter/files-upload-counter.component";
import { getControlInFormArray } from "@root/shared/utilities/form.utilities";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { ICreateDocumentRequest } from "@root/shared/interfaces/upload-document-payload.model";
import {
  extractValidFiles,
  extractValidFilesByExtension,
} from "@root/data/market/documents/utilities/documents.utilities";
import { IUploadMultiFilesInputs } from "@root/shared/interfaces/upload-multi-files-component-inputs.interface";
import { FileUploadSuccessToastComponent } from "@root/shared/file-upload-success-toast/file-upload-success-toast.component";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";

@Component({
  selector: "est-document-upload-dialog",
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltip,
    MatProgressBar,
    NgOptimizedImage,
    ButtonComponent,
    CreationSuccessMessageComponent,
    InputComponent,
    LanguageDropdownComponent,
    ListSelectPropertiesComponent,
    ListSelectTeamsComponent,
    MatStep,
    MatStepper,
    NgIf,
    PhoneInputComponent,
    SelectDivisionFieldComponent,
    SelectFieldComponent,
    UploadFileDropZoneComponent,
    MatSlideToggle,
    DialogHeaderComponent,
    NgClass,
  ],
  templateUrl: "./document-upload-dialog.component.html",
  styleUrl: "./document-upload-dialog.component.scss",
})
export class DocumentUploadDialogComponent extends BaseStepperComponent implements OnInit {
  readonly #documentService = inject(DocumentService);
  protected dialogRef: MatDialogRef<DocumentUploadDialogComponent> = inject(MatDialogRef);
  protected componentInputs: IUploadMultiFilesInputs = inject(MAT_DIALOG_DATA);
  protected readonly getFileSizeInKb = getFileSize;
  protected readonly getFileSize = getFileSize;
  @ViewChild("uploadButton") uploadButton!: ButtonComponent;
  snackBarService = inject(SnackbarService);
  fb = inject(NonNullableFormBuilder);
  documentTypes: IDropdownOption[] = [];
  totalTabsNo = 2;
  control = this.fb.control<ICreateDocumentRequest[]>([]);
  getControlInFormArray = getControlInFormArray;
  documentFileNameArrayControl = this.fb.array([this.fileTypeGroup()]);
  protected readonly FileExtension = FileExtension;
  maxFileSize = 5;
  isDocumentCreated: boolean = false;
  isUploading: boolean = false;
  supportedTypes: FileExtension[] = [FileExtension.PDF];

  fileTypeGroup(): FormGroup {
    return this.fb.group({
      DocumentName: [""],
      DocumentTypeId: [null],
      FormFile: [null],
      Active: [true],
    });
  }
  constructExitFileGroup(file: ICreateDocumentRequest): FormGroup {
    return this.fb.group({
      DocumentName: [file.DocumentName],
      DocumentTypeId: [file.DocumentTypeId],
      FormFile: [file.FormFile],
      Active: [file.Active],
    });
  }
  ngOnInit() {
    this.#documentService.getDocumentTypes().subscribe((documentTypes) => {
      this.documentTypes = documentTypes;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteFile(index: number) {
    this.control.value.splice(index, 1);
  }

  toggleDocumentActivation(checked: boolean, document: ICreateDocumentRequest, index: number) {
    document.Active = checked;
    const files = this.documentFileNameArrayControl.value;
    files[index].Active = checked;
    this.documentFileNameArrayControl.patchValue(files);
  }

  #uploadDocument() {
    this.#documentService
      .uploadDocuments(
        this.documentFileNameArrayControl.value as ICreateDocumentRequest[],
        this.componentInputs.sourceLevel,
        this.componentInputs.routeId,
      )
      .subscribe({ complete: () => this.#finishUploading() });
  }

  #finishUploading(): void {
    this.isDocumentCreated = true;
    this.dialogRef.close(this.isDocumentCreated);
    this.snackBarService.closeCurrentSnackbar();
    this.snackBarService.open(
      SnackbarType.Success,
      "SNACKBAR.SUCCESS.DESCRIPTION",
      FileUploadSuccessToastComponent,
      {
        totalFiles: this.control.value.length,
      },
      5000,
    );
  }

  #createDocumentFrom(file: File): ICreateDocumentRequest {
    const defaultDocumentTypeId = this.#getDefaultDocumentTypeId();
    return {
      DocumentName: file.name,
      DocumentTypeId: defaultDocumentTypeId,
      FormFile: file,
      Active: true,
    };
  }

  #getDefaultDocumentTypeId(): number {
    return this.documentTypes && this.documentTypes.length > 0 ? this.documentTypes[0].value : 0;
  }

  #clearDocumentFileNameArrayControl(): void {
    this.documentFileNameArrayControl.clear();
  }

  #updateControlValueWithFiles(files: FileList): void {
    const newDocuments = Array.from(files).map((file) => this.#createDocumentFrom(file));
    const updatedValue = [...this.control.value, ...newDocuments];
    this.control.setValue(updatedValue);
  }

  #updateDocumentFileNameArrayControl(): void {
    this.control.value.forEach((document) => {
      this.documentFileNameArrayControl.push(this.constructExitFileGroup(document));
    });
  }

  updateControl(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;
    if (!files || files.length <= 0) {
      element.value = "";
      return;
    }
    if (extractValidFilesByExtension(files, this.supportedTypes).length === files.length) {
      if (extractValidFiles(files, this.maxFileSize).length === files.length) {
        this.#clearDocumentFileNameArrayControl();
        this.#updateControlValueWithFiles(files);
        this.#updateDocumentFileNameArrayControl();
      } else {
        this.snackBarService.open(SnackbarType.Error, "SNACKBAR.ERROR.MAX_FILE_REACHED");
      }
    } else {
      this.snackBarService.open(SnackbarType.Error, "SNACKBAR.ERROR.UNSUPPORTED_FILE_TYPE");
    }
  }

  submitForm(): void {
    this.isUploading = true;
    this.#uploadDocument();
    this.snackBarService.open(
      SnackbarType.Loading,
      "SNACKBAR.LOADING.DESCRIPTION",
      FilesUploadCounterComponent,
      {
        totalFiles: this.control.value.length,
      },
      Infinity,
    );
  }
}
