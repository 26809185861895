import { NgClass, NgStyle } from "@angular/common";
import { Component, DestroyRef, inject, input, OnInit, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { INoteComment } from "@root/data/market/notes/models/note-comment.model";
import { INote } from "@root/data/market/notes/models/note.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { NoteCommentComponent } from "@root/shared/note-view/components/note-comment/note-comment.component";
import { NoteCategoryLabelComponent } from "@root/shared/note-view/components/note/components/note-category-label/note-category-label.component";
import { NoteBinder } from "@root/shared/note-view/components/note/note.binder";
import { NoteFormControls } from "@root/shared/note-view/components/note/note.form-controls";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import {
  castControlFromAbstractToFormArray,
  castControlFromAbstractToFormControl,
  castControlFromAbstractToFormGroup,
} from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-note",
  standalone: true,
  imports: [
    InputComponent,
    SelectFieldComponent,
    TextAreaComponent,
    ButtonComponent,
    SmallHeaderComponent,
    NgClass,
    NgStyle,
    NoteCategoryLabelComponent,
    TranslateModule,
    CustomDatePipe,
    NoteCommentComponent,
  ],
  templateUrl: "./note.component.html",
  styleUrl: "./note.component.scss",
})
export class NoteComponent implements OnInit {
  noteCategories = input.required<IDropdownOption[]>();
  control = input.required<FormGroup>();
  isLoading = input(false);
  readonly #formControl = inject(NoteFormControls);
  updateNote = output<INote>();
  deleteNote = output<INote>();
  createComment = output<INoteComment>();
  updateComment = output<INoteComment>();
  deleteComment = output<INoteComment>();
  protected initialNoteText = "";
  protected initialSubject = "";
  protected initialNoteCategoryId = -1;
  protected commentControl = new FormControl<string>("", { nonNullable: true });
  protected isEditing = false;
  protected isConfirmingDeletion = false;
  readonly #destroyRef = inject(DestroyRef);
  readonly #noteBinder = inject(NoteBinder);
  protected collapsed: boolean = true;
  protected readonly castControlFromAbstractToFormArray = castControlFromAbstractToFormArray;
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected readonly castControlFromAbstractToFormGroup = castControlFromAbstractToFormGroup;

  ngOnInit() {
    this.#noteBinder.onEditing.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((ids) => {
      this.isEditing = ids.noteId === this.control().controls["id"].value;
      if (this.isEditing) {
        this.collapsed = false;
      }
    });

    this.#noteBinder.onDeleting
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((ids) => (this.isConfirmingDeletion = ids.noteId === this.control().controls["id"].value));
  }

  protected toggleEditing() {
    if (this.isEditing) {
      this.control().controls["message"].setValue(this.initialNoteText);
      this.control().controls["subject"].setValue(this.initialSubject);
      this.control().controls["noteCategoryId"].setValue(this.initialNoteCategoryId);
      this.isEditing = !this.isEditing;
    } else {
      this.initialNoteText = this.control().controls["message"].value;
      this.initialSubject = this.control().controls["subject"].value;
      this.initialNoteCategoryId = this.control().controls["noteCategoryId"].value;
      this.#noteBinder.emitEditing({ noteId: this.control().controls["id"].value });
    }
  }

  protected toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  protected save() {
    this.isEditing = false;
    this.updateNote.emit({
      ...this.control().value,
      noteCategory: { id: this.control().value.noteCategoryId },
      id: this.control().controls["id"].value,
    });
  }

  protected toggleDeleting() {
    if (this.isConfirmingDeletion) {
      this.isConfirmingDeletion = !this.isConfirmingDeletion;
    } else {
      this.#noteBinder.emitDeleting({ noteId: this.control().controls["id"].value });
    }
  }

  protected delete() {
    this.deleteNote.emit(this.control().value);
  }

  protected createNoteComment() {
    if (this.commentControl.value) {
      const comment = {
        noteId: this.control().controls["id"].value,
        comment: this.commentControl.value,
        noteCategoryId: this.control().controls["noteCategoryId"].value,
      };
      this.commentControl.reset();

      const commentControl = this.#formControl.constructCommentControl();
      commentControl.patchValue(comment);
      castControlFromAbstractToFormArray(this.control().controls["comments"]).push(commentControl);
    }
  }

  protected deleteNoteComment(noteComment: INoteComment) {
    const index = this.control().controls["comments"].value.findIndex(
      (comment: { id: number | undefined }) => comment.id === noteComment.id,
    );
    if (index === -1) return;
    castControlFromAbstractToFormArray(this.control().controls["comments"]).removeAt(index);
  }
}
