import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";

@Injectable({
  providedIn: "root",
})
export class NoteCategoryRepository {
  readonly #http = inject(HttpClient);

  createNoteCategory(body: any): Observable<void> {
    return this.#http.post<void>(APIS.noteCategories.createNoteCategory, body);
  }

  lookUpNoteCategories(): Observable<INoteCategory[]> {
    return this.#http.get<INoteCategory[]>(APIS.noteCategories.lookUpNoteCategories);
  }
}
