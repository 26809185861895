<div class="flex gap-2">
  <p
    class="text-sm cursor-default font-medium px-2 py-1 rounded w-fit"
    [ngClass]="textColourClass"
    [ngStyle]="{ 'background-color': noteCategory().colour }"
  >
    {{ label | uppercase }}
  </p>
  @if (noteCategory().gdprProtected) {
    <div
      class="cursor-default group relative text-sm font-medium px-2 py-1 rounded w-fit flex gap-1 text-white bg-black-500"
    >
      <img ngSrc="assets/images/icons/user-secret.svg" alt="User secret" height="12" width="12" />
      <span>{{ "GDPR_PROTECTED" | translate }}</span>
      @if (!canView()) {
        <div
          class="group-hover:block hidden text-black font-light text-[0.75rem] leading-[0.875rem] absolute bg-white shadow translate-y-[-95%] translate-x-[90%] top-0 right-0 w-[12rem] px-4 py-2 rounded-lg"
        >
          {{ "CUSTOMER_CARD.NOTES.GDPR_NO_ACCESS" | translate }}
        </div>
      }
    </div>
  }
</div>
