import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { INote } from "@root/data/market/notes/models/note.model";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { ILookUp } from "@root/shared/interfaces/look-up.interface";
import { INoteComment } from "@root/data/market/notes/models/note-comment.model";

@Injectable({
  providedIn: "root",
})
export class NotesRepository {
  readonly #http = inject(HttpClient);

  lookUpCreatedByUsers(journeyId: number): Observable<ILookUp[]> {
    return this.#http.get<ILookUp[]>(APIS.notes.lookUpCreatedByUsers(journeyId));
  }

  createNote(journeyId: number, subject: string, message: string, noteCategoryId: number): Observable<void> {
    return this.#http.post<void>(APIS.notes.createNote(journeyId), {
      subject,
      message,
      noteCategoryId,
    });
  }

  updateNote(
    journeyId: number,
    noteId: number,
    subject: string,
    message: string,
    noteCategoryId: number,
  ): Observable<void> {
    return this.#http.put<void>(APIS.notes.updateNote(journeyId, noteId), {
      subject,
      message,
      noteCategoryId,
    });
  }

  deleteNote(journeyId: number, noteId: number): Observable<void> {
    return this.#http.delete<void>(APIS.notes.deleteNote(journeyId, noteId));
  }

  listPaginatedJourneyNotes(
    params: IPaginationPayload & {
      noteCategoryIds?: number[];
      userIds?: number[];
      startDate?: string;
      endDate?: string;
    },
    journeyId: number,
  ): Observable<IPaginatedResponse<INote>> {
    return this.#http.get<IPaginatedResponse<INote>>(APIS.notes.listJourneyNotes(journeyId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  createNoteComment(body: INoteComment, journeyId: number, noteId: number): Observable<void> {
    return this.#http.post<void>(APIS.notes.noteComments.createNoteComment(journeyId, noteId), body);
  }

  updateNoteComment(journeyId: number, noteCommentId: number, body: INoteComment): Observable<void> {
    return this.#http.put<void>(APIS.notes.noteComments.updateNoteComment(journeyId, noteCommentId), body);
  }

  deleteNoteComment(journeyId: number, noteCommentId: number): Observable<void> {
    return this.#http.delete<void>(APIS.notes.noteComments.deleteNoteComment(journeyId, noteCommentId));
  }
}
