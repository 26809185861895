@if (!initialLoading) {
  <div class="flex-col flex gap-6 relative">
    <div class="flex-col flex gap-4">
      <est-small-header
        appliedClasses="!mb-2"
        heading="CUSTOMER_CARD.NOTES.INTERNAL_NOTE"
        subheading="CUSTOMER_CARD.NOTES.DESCRIPTION"
      ></est-small-header>
      <div class="w-full p-6 flex-col flex gap-4 bg-gray-50 border border-black-300 rounded-lg">
        <est-small-header
          appliedClasses="!mb-0"
          heading="CUSTOMER_CARD.NOTES.ADD_NOTE"
          tooltip="CUSTOMER_CARD.NOTES.ADD_NOTE"
        ></est-small-header>
        <div class="flex gap-4 flex-wrap">
          <est-input
            [control]="newNoteFormGroup.controls.subject"
            appliedClass="xl:w-[37.5rem] md:w-[18.75rem]"
            label="CUSTOMER_CARD.NOTES.SUBJECT"
          ></est-input>
          <est-select-field
            appliedClass="xl:w-[18.75rem] md:w-[9.375rem]"
            [control]="newNoteFormGroup.controls.noteCategoryId"
            [selectMultipleOptions]="false"
            dropDownLabel="CATEGORY"
            [dropDownOptions]="noteCategoryOptions"
          ></est-select-field>
        </div>
        <div class="flex flex-wrap gap-6 items-end">
          <est-text-area
            class="w-full max-w-[57.25rem]"
            [control]="newNoteFormGroup.controls.message"
            appliedClass="w-full !h-[9.5rem]"
            label="CUSTOMER_CARD.NOTES.INTERNAL_NOTE"
            placeholder="CUSTOMER_CARD.NOTES.WRITE_NOTE"
          ></est-text-area>
          <est-button
            buttonType="button"
            buttonStyleType="button--primary"
            label="CREATE"
            (click)="createNewNote()"
            [isLoading]="isLoading && newNoteFormGroup.valid"
          ></est-button>
        </div>
      </div>
    </div>
    <div>
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <div class="w-full flex flex-wrap gap-4 justify-end">
      <est-select-field
        appliedClass="xl:w-[9.375rem]"
        [selectMultipleOptions]="true"
        dropDownLabel="CATEGORY"
        [dropDownOptions]="noteCategoryOptions"
        [selectSignal]="noteCategoryIdsSignal"
      ></est-select-field>
      <est-select-field
        [selectMultipleOptions]="true"
        dropDownLabel="CREATED_BY"
        [dropDownOptions]="createdByUsers"
        [selectSignal]="userIdsSignal"
      ></est-select-field>
      <est-datepicker-range-input
        appliedClasses="w-[18.75rem]"
        [startDateSignal]="startDateSignal"
        [endDateSignal]="endDateSignal"
        [isEndDateIncluded]="true"
        [isStartDateIncluded]="true"
      ></est-datepicker-range-input>
      <est-search-input [searchSignal]="searchSignal"></est-search-input>
    </div>
    <div
      #scrollContainer
      (scroll)="
        isElementScrolledToTheBottom(scrollContainer, 50) &&
          !this.isNoMoreResults &&
          !this.isScrollLoading &&
          this.loadNextSet()
      "
      class="flex flex-col gap-2 overflow-auto max-h-[38rem]"
    >
      @for (noteGroup of noteFormArray().controls; track noteGroup) {
        <est-note
          [control]="noteGroup"
          [noteCategories]="noteCategoryOptions"
          (updateNote)="updateNote($event)"
          (deleteNote)="deleteNote($event)"
          [isLoading]="isLoading"
        ></est-note>
      }
    </div>
    @if (isScrollLoading) {
      <est-loading-overlay appliedClasses="absolute"></est-loading-overlay>
    }
  </div>
} @else {
  <est-loading-overlay></est-loading-overlay>
}
