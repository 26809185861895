import { inject, Injectable } from "@angular/core";
import { NoteCategoryRepository } from "@root/data/market/noteCategories/repositories/note-category.repository";
import { Observable } from "rxjs";
import { INoteCategory } from "@root/data/market/noteCategories/models/note-category.model";

@Injectable({
  providedIn: "root",
})
export class NoteCategoryService {
  readonly #noteCategoryRepository = inject(NoteCategoryRepository);

  createNoteCategory(body: any): Observable<void> {
    return this.#noteCategoryRepository.createNoteCategory(body);
  }

  lookUpNoteCategories(): Observable<INoteCategory[]> {
    return this.#noteCategoryRepository.lookUpNoteCategories();
  }
}
