<div class="min-h-[10rem] relative p-6 flex flex-col gap-6 rounded-lg bg-gray-50 border border-black-300">
  @if (isConfirmingDeletion) {
    <div class="absolute z-10 bg-white rounded-lg opacity-90 top-0 left-0 w-full h-full"></div>
    <div class="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-6">
      <est-button
        label="COLD_FEET"
        buttonType="button"
        buttonStyleType="button--secondary"
        (click)="toggleDeleting()"
      ></est-button>
      <est-button
        label="CUSTOMER_CARD.NOTES.DELETE_NOTE"
        buttonType="button"
        buttonStyleType="button--primary"
        (click)="delete()"
      ></est-button>
    </div>
  }
  <div class="flex">
    <div class="flex flex-col gap-4">
      @if (isEditing) {
        <div class="flex flex-wrap gap-4">
          <est-input
            [control]="castControlFromAbstractToFormControl(control().controls['subject'])"
            appliedClass="xl:w-[37.5rem] md:w-[18.75rem]"
            label="CUSTOMER_CARD.NOTES.SUBJECT"
          ></est-input>
          <est-select-field
            [control]="castControlFromAbstractToFormControl(control().controls['noteCategoryId'])"
            [selectMultipleOptions]="false"
            dropDownLabel="CATEGORY"
            [dropDownOptions]="noteCategories()"
          ></est-select-field>
        </div>
        <div class="flex flex-wrap gap-6 items-end">
          <est-text-area
            class="w-full"
            appliedClass="!w-full"
            [control]="castControlFromAbstractToFormControl(control().controls['message'])"
            label="CUSTOMER_CARD.NOTES.INTERNAL_NOTE"
            placeholder="CUSTOMER_CARD.NOTES.WRITE_NOTE"
          ></est-text-area>
          <est-button
            buttonType="button"
            buttonStyleType="button--primary"
            label="SAVE"
            (click)="save()"
            [isLoading]="isLoading() && isEditing"
          ></est-button>
        </div>
      } @else {
        <div class="flex flex-col gap-2">
          <est-small-header appliedClasses="!mb-2" [heading]="control().controls['subject'].value"></est-small-header>
          <est-note-category-label
            [noteCategory]="this.control().controls['noteCategory'].value"
            [canView]="this.control().controls['canView'].value"
          ></est-note-category-label>
          <div class="flex gap-4 font-light text-[0.75rem]">
            <p>{{ "CREATED" | translate }}: {{ this.control().controls["createdAt"].value | customDate: true }}</p>
            <p>{{ "CREATED_BY" | translate }}: {{ this.control().controls["createdBy"].value }}</p>
          </div>
          <div class="relative text-sm font-light max-w-[58.125rem] whitespace-pre-wrap">
            {{ this.control().controls["message"].value }}
            @if (!this.control().controls["canView"]) {
              <div
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full backdrop-blur-[0.2rem] z-20"
              ></div>
            }
          </div>
        </div>
      }
    </div>
    <div class="flex gap-2 absolute top-6 right-6">
      <est-button
        appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 !rounded"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label=""
        imagePosition="center"
        imageSrc="assets/images/icons/edit-pencil.svg"
        (click)="toggleEditing()"
        appliedSpinnerClasses="!fill-primary"
        [disabled]="!this.control().controls['isOwner'].value"
      ></est-button>
      <est-button
        appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 !rounded"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label=""
        imagePosition="center"
        imageSrc="assets/images/icons/trash-icon.svg"
        appliedSpinnerClasses="!fill-primary"
        [disabled]="!this.control().controls['isOwner'].value"
        (click)="toggleDeleting()"
      ></est-button>
      <est-button
        appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 !rounded"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label=""
        imagePosition="center"
        imageSrc="assets/images/icons/arrow-down.svg"
        [ngClass]="{ '!rotate-180': !collapsed }"
        (click)="toggleCollapsed()"
        [disabled]="!this.control().controls['canView'].value"
      ></est-button>
    </div>
  </div>
  @if (!collapsed) {
    @if (castControlFromAbstractToFormArray(control().controls["comments"]).controls.length > 0) {
      <div class="flex-col flex ms-6 gap-6">
        @for (
          commentControl of castControlFromAbstractToFormArray(control().controls["comments"]).controls;
          track commentControl
        ) {
          <div>
            <hr class="border-black-100 absolute start-0 end-0" />
          </div>
          <est-note-comment
            (deleteNoteComment)="deleteNoteComment($event)"
            [formGroup]="castControlFromAbstractToFormGroup(commentControl)"
          ></est-note-comment>
        }
      </div>
    }
    <div>
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <div class="flex flex-wrap gap-6 items-end">
      <est-text-area
        [control]="commentControl"
        class="w-full max-w-[57.25rem]"
        appliedClass="w-full h-fit"
        placeholder="CUSTOMER_CARD.NOTES.ADD_COMMENT"
      ></est-text-area>
      <est-button
        [disabled]="!commentControl.value"
        (click)="createNoteComment()"
        label="CREATE"
        buttonType="button"
        buttonStyleType="button--primary"
      ></est-button>
    </div>
  }
</div>
