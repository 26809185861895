import { Component } from "@angular/core";
import { BaseCustomerCardTabComponentComponent } from "@root/shared/abstracts/base-customer-card-tab-component/base-customer-card-tab-component.component";
import { NoteViewComponent } from "@root/shared/note-view/note-view.component";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "est-customer-card-notes",
  standalone: true,
  imports: [NoteViewComponent, JsonPipe],
  templateUrl: "./customer-card-notes.component.html",
  styleUrl: "./customer-card-notes.component.scss",
})
export class CustomerCardNotesComponent extends BaseCustomerCardTabComponentComponent {
  override loadData(): void {}
}
