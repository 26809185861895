import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";

@Component({
  selector: "est-upload-file-drop-zone",
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage, NgClass],
  templateUrl: "./upload-file-drop-zone.component.html",
  styleUrl: "./upload-file-drop-zone.component.scss",
})
export class UploadFileDropZoneComponent {
  supportedTypes = input<FileExtension[]>([]); // Supported types without . in upper or lower case
  maxFileSize = input<number>(5);
  maxFileSizeChange = output<number>();
  recommendedImageWidth = input<number | null>(null);
  recommendedImageHeight = input<number | null>(null);
  appliedClasses = input<string>("");
  isMultiple = input<boolean>(false);
  fileUpload = output<Event>();

  getFileTypesString(): string {
    return this.supportedTypes().join(", ");
  }

  getSupportedFileTypesExtensions(): string {
    return this.supportedTypes()
      .map((type) => "." + type.toLowerCase())
      .join(", ");
  }

  emitFileUploadEvent(event: Event) {
    this.fileUpload.emit(event);
  }
}
