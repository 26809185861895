import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, input, WritableSignal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideLuxonDateAdapter } from "@angular/material-luxon-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { dateFormat } from "@root/shared/datepicker/date-format-luxon";

@Component({
  selector: "est-datepicker-range-input",
  standalone: true,
  providers: [provideLuxonDateAdapter(dateFormat)],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    TranslateModule,
    NgClass,
  ],
  templateUrl: "./datepicker-range-input.component.html",
  styleUrl: "./datepicker-range-input.component.scss",
})
export class DatepickerRangeInputComponent {
  isDisabled = input<boolean>(false);
  appliedClasses = input("");
  label = input("");
  startDateSignal = input<WritableSignal<Date | null>>();
  endDateSignal = input<WritableSignal<Date | null>>();
  isStartDateIncluded = input.required<boolean>();
  isEndDateIncluded = input.required<boolean>();
  today: Date = new Date();
}
