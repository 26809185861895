import { LowerCasePipe } from "@angular/common";
import { Component, effect, EffectRef, inject, input, signal, WritableSignal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { CustomerJourneysService } from "@root/data/market/customer-journeys/services/customer-journeys.service";
import { ICustomerOverview } from "@root/data/market/legalEntities/models/customer-overview.model";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { CUSTOMER_OVERVIEW_HEADERS } from "@root/shared/customer-card/components/customer-card-overview/components/customer-overview/customer-overview.headers";
import { HeartButtonComponent } from "@root/shared/heart-button/heart-button.component";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  selector: "est-customer-overview",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    TranslateModule,
    SmallListTableComponent,
    PaginationComponent,
    CustomDatePipe,
    HeartButtonComponent,
    LowerCasePipe,
  ],
  templateUrl: "./customer-overview.component.html",
  styleUrl: "./customer-overview.component.scss",
})
export class CustomerOverviewComponent extends BasePaginatedTableWithSearchComponent {
  legalEntityId = input.required<number>();
  customerOverview = input.required<ICustomerOverview>();
  favouriteControl = input.required<FormControl<boolean>>();
  readonly #journeyService = inject(CustomerJourneysService);

  override headers: ITableHeader[] = CUSTOMER_OVERVIEW_HEADERS;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "stage",
    sortDescending: false,
  });
  override loadDataEffect$: EffectRef = effect(() => this.#loadWithParams());
  override loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#journeyService
      .getCustomerJourneysWithParams({
        ...params,
        legalEntityId: this.legalEntityId(),
      })
      .subscribe((paginatedJourneys) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedJourneys);
        this.isTableLoading = false;
      });
  }

  #loadWithParams() {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: "",
    });
  }

  getInternationalPhoneNumber(): string {
    return this.customerOverview().phoneNumber.includes("+")
      ? this.customerOverview().phoneNumber
      : `${this.customerOverview().phoneIsdCode}` + `${this.customerOverview().phoneNumber}`;
  }
}
