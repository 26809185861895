import { inject, Injectable } from "@angular/core";
import { NotesRepository } from "@root/data/market/notes/repositories/notes.repository";
import { Observable } from "rxjs";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { INote } from "@root/data/market/notes/models/note.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapNotesToSignalPaginatedResponse } from "@root/data/market/notes/utilities/notes.utilities";
import { INoteComment } from "@root/data/market/notes/models/note-comment.model";
import { ILookUp } from "@root/shared/interfaces/look-up.interface";

@Injectable({
  providedIn: "root",
})
export class NotesService {
  readonly #noteRepository = inject(NotesRepository);

  lookUpCreatedByUsers(journeyId: number): Observable<ILookUp[]> {
    return this.#noteRepository.lookUpCreatedByUsers(journeyId);
  }

  createNote(journeyId: number, subject: string, message: string, noteCategoryId: number): Observable<void> {
    return this.#noteRepository.createNote(journeyId, subject, message, noteCategoryId);
  }

  updateNote(
    journeyId: number,
    noteId: number,
    subject: string,
    message: string,
    noteCategoryId: number,
  ): Observable<void> {
    return this.#noteRepository.updateNote(journeyId, noteId, subject, message, noteCategoryId);
  }

  deleteNote(journeyId: number, noteId: number): Observable<void> {
    return this.#noteRepository.deleteNote(journeyId, noteId);
  }

  getPaginatedJourneyNotes(
    params: IPaginationPayload & {
      noteCategoryIds?: number[];
      userIds?: number[];
      startDate?: string;
      endDate?: string;
    },
    journeyId: number,
  ): Observable<ISignalPaginatedResponse<INote>> {
    return this.#noteRepository.listPaginatedJourneyNotes(params, journeyId).pipe(mapNotesToSignalPaginatedResponse());
  }

  createNoteComment(body: INoteComment, journeyId: number, noteId: number): Observable<void> {
    return this.#noteRepository.createNoteComment(body, journeyId, noteId);
  }

  updateNoteComment(journeyId: number, noteCommentId: number, body: INoteComment): Observable<void> {
    return this.#noteRepository.updateNoteComment(journeyId, noteCommentId, body);
  }

  deleteNoteComment(journeyId: number, noteCommentId: number): Observable<void> {
    return this.#noteRepository.deleteNoteComment(journeyId, noteCommentId);
  }
}
