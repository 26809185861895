import { Component, effect, signal, WritableSignal } from "@angular/core";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";

@Component({
  standalone: true,
  template: "",
  styles: "",
})
export abstract class BasePaginatedScrollWithSearchAbstractComponent {
  // This component should be inherited by components that contain a scrollable view with pagination & search
  paginatedData = constructInitialSignalPaginatedResponse();
  isScrollLoading = false;
  isNoMoreResults = false;
  searchSignal: WritableSignal<string> = signal("");
  readonly #searchAndFilterOptionsEffect$ = effect(
    () => {
      this.getHttpServiceCall(1); // Registers any other signals than searching in the effect, so it would run if they changed
      this.searchSignal();
      this.resetPagination();
      this.loadOptions(1);
    },
    {
      allowSignalWrites: true,
    },
  );

  abstract getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse> | void;

  loadNextSet() {
    if (this.paginatedData.totalPages() && this.paginatedData.currentPage() >= this.paginatedData.totalPages()!) {
      return;
    }
    this.loadOptions(this.paginatedData.currentPage() + 1);
  }

  loadOptions(pageNumber: number): void {
    this.paginatedData.currentPage.set(pageNumber);
    this.isScrollLoading = true;
    this.getHttpServiceCall(pageNumber)?.subscribe((signalPaginatedOptions) => {
      this.paginatedData.totalCount.set(signalPaginatedOptions.totalCount());
      this.paginatedData.totalPages.set(signalPaginatedOptions.totalPages());
      this.paginatedData.results.set(this.paginatedData.results().concat(signalPaginatedOptions.results()));
      this.isScrollLoading = false;
      if (signalPaginatedOptions.results().length === 0) {
        this.isNoMoreResults = true;
      }
    });
  }

  protected resetPagination(): void {
    this.paginatedData.currentPage.set(1);
    this.paginatedData.results.set([]);
    this.isNoMoreResults = false;
  }
}
