import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NoteBinder {
  readonly #editing$ = new Subject<{ noteId?: number; commentId?: number }>();
  readonly #deleting$ = new Subject<{ noteId?: number; commentId?: number }>();
  onEditing = this.#editing$.asObservable();
  onDeleting = this.#deleting$.asObservable();

  emitEditing(ids: { noteId?: number; commentId?: number }): void {
    this.#editing$.next(ids);
  }

  emitDeleting(ids: { noteId?: number; commentId?: number }): void {
    this.#deleting$.next(ids);
  }
}
